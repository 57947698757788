import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import {
  boxShadow,
  fontSemiBold,
  colorBackground,
  fontBlack,
  fontRegular,
  fontLight,
} from '../../../mui'
import { content } from 'html2canvas/dist/types/css/property-descriptors/content'

export const useSearchStyles = makeStyles({
  root: {
    width: 180,
    height: 38,
    background: 'none',
    '& input': {
      paddingRight: 0,
    },
    '& input::placeholder': {
      fontSize: 13,
      fontFamily: fontSemiBold,
    },
    '& .MuiInputAdornment-root': {
      marginLeft: 0,
    },
  },
})

export const useTableStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      fontSize: 14,
      fontFamily: fontRegular,
      '& thead': {
        background: colorBackground,
        '& th': {
          fontFamily: fontBlack,
          color: '#8B929B',
          whiteSpace: 'nowrap',
        },
      },
      '& tbody': {
        '& tr.MuiTableRow-hover': {
          '&:hover': {
            backgroundColor: theme.palette.secondary.light + ' !important',
            cursor: 'pointer',
          },
        },
        '& tr': {
          backgroundColor: '#FFF',
        },
      },
      '& th, td': {
        border: 'none',
        [theme.breakpoints.only("xs")]: {
          fontSize: '12px',
        },
      },
      '& td:first-child, & th:first-child': {
        paddingLeft: '24px !important',
        [theme.breakpoints.only("xs")]: {
          paddingLeft: '0 !important',
        },
      },
      '& td:last-child, & th:last-child': {
        paddingRight: '24px !important',
        [theme.breakpoints.only("xs")]: {
          paddingRight: '0 !important',
        },
      },
      /*'& th.wideVisible, & td.wideVisible, & th:first-child, & td:first-child': {
        [theme.breakpoints.only("xs")]: {
          display: 'none',
        },
      },*/
      '& th.wideVisible, & td.wideVisible, td.MuiTableCell-paddingCheckbox, th.MuiTableCell-paddingCheckbox': {
        [theme.breakpoints.only("xs")]: {
          display: 'none',
        },
      },
      '& th.mobileStatus': {
        [theme.breakpoints.only("xs")]: {
          minWidth: 'auto!important',
        },
      },
    },
    card: {
      padding: '16px 0',
      background: '#FFF',
      borderRadius: 15,
      boxShadow: boxShadow,
      '& td, th': {
        padding: '0 16px',
        height: 52,
      },
    },
    developer: {
      '& thead': {
        backgroundColor: '#FFF',
        '& th': {
          fontFamily: fontSemiBold,
          color: theme.palette.black.main,
        },
      },
      '& tbody tr:nth-of-type(odd)': {
        backgroundColor: '#F0F2FA',
        opacity: 0.9,
      },
      '& td, & th': {
        fontSize: 16,
        fontFamily: fontLight,
        border: 0,
      },
      '& th': {
        padding: '5px 10px',
        fontFamily: fontSemiBold,
      },
    },
    developerTypes: {
      '& thead': {
        backgroundColor: '#FFF',
        '& th': {
          fontFamily: fontSemiBold,
          color: theme.palette.black.main,
        },
      },
      '& tbody tr:nth-of-type(odd)': {
        background: 'rgba(73,204,144,.1)',
      },
      '& td, & th': {
        fontSize: 16,
        fontFamily: fontLight,
        border: 0,
      },
      '& th': {
        padding: '5px 10px',
        fontFamily: fontSemiBold,
      },
      '& .lookup_id': {
        width: '6rem',
      },
      '& .lookup_type': {
        width: '24rem',
      },
    },
    rowEven: {
      backgroundColor: colorBackground,
    },
    sizeSmall: {
      padding: '0 8px !important',
      height: 22,
    },
    sizeMedium: {
      padding: '0 16px !important',
      height: 53,
    },
    resultsCard: {
      width: "90%",
      padding: '16px 0',
      background: '#FFF',
      borderRadius: 15,
      boxShadow: boxShadow,
      '& td, th': {
        padding: '0 16px',
        height: 50,
      },
      '& thead': {
        backgroundColor: '#FFF',
        '& th': {
          color: '#ACAFB6',
          fontSize: '14px',
          fontWeight: 400,
          fontFamily: fontSemiBold,
        },
      },
      '& tbody tr:nth-of-type(even)': {
        background: '#FFF',
      },
      '& tbody tr:nth-of-type(odd)': {
        background: 'rgba(242, 246, 255, 0.80)',
      },
      '& tbody td:nth-child(2)': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  })
})

export const useTablePaginationStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiPaginationItem-root': {
      fontFamily: fontSemiBold,
      color: theme.palette.secondary.dark,
      background: 'none',
    },
    '& .MuiPaginationItem-previousNext': {
      color: '#FFF',
      background: theme.palette.primary.main,
    },
    '& .Mui-selected': {
      color: theme.palette.black.main,
    },
    '& .Mui-disabled': {
      color: theme.palette.secondary.dark,
      background: '#FFF',
      opacity: 1,
    },
  },
}))
