import { Rowing } from "@mui/icons-material"
import React from "react"
import { SearchResult } from "../store/searcher/reducer"

interface CircularProgressProps {
  results : SearchResult
}

const ProgressCircle : React.FC<CircularProgressProps> = ({ results }) => {

  const data : any = results
  const maxSources = data && data.sources && Array.isArray(data.sources) ? data.sources.length : 0
  const collectedSorces = data && data.apiDataEntities && Array.isArray(data.apiDataEntities) ? data.apiDataEntities.length : 0
  let strokeWidth_1 = 15
  const strokeWidth_2 = 25
  const collectedAmount = collectedSorces > 0 ? maxSources - collectedSorces : 0
  const percentage = collectedSorces > 0 ? Math.round(collectedSorces / maxSources * 100) : 0
  let collectedProgress = percentage >= 100 ? 100 : percentage
  let strokeDashoffset = collectedAmount == 0 ? 722.2 : 51.5 * collectedAmount
  let allignPercentage = "80px"
  allignPercentage = collectedProgress >= 10 && collectedProgress <= 99 ? "65px" : allignPercentage
  allignPercentage = collectedProgress >= 100 ? "45px" : allignPercentage
  let color = "#04163e"
  color = collectedProgress >= 100 ? "#4bde97" : color
  strokeWidth_1 = collectedProgress >= 100 ? strokeWidth_2 : strokeWidth_1
  

  if (results.status === "finished") {
    color = "#4bde97"
    allignPercentage = "45px"
    strokeDashoffset =  722.2
    collectedProgress = 100
    strokeWidth_1 = strokeWidth_2
  }

  return (
    <svg
    width="45"
    height="45"
    viewBox="-31.25 -31.25 312.5 312.5"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: "rotate(-90deg)" }}
  >
    <circle
      r="115"
      cx="125"
      cy="125"
      fill="transparent"
      stroke={color}
      strokeWidth={strokeWidth_1}
      strokeDasharray="722.2px"
      strokeDashoffset="0"
    ></circle>
    <circle
      r="115"
      cx="125"
      cy="125"
      stroke="#3898d3"
      strokeWidth={strokeWidth_2}
      strokeLinecap="round"
      strokeDashoffset={strokeDashoffset}
      fill="transparent"
      strokeDasharray="722.2px"
    ></circle>
    <text
      x={allignPercentage}
      y="145px"
      fill="#000000"
      fontSize="65px"
      fontWeight="bold"
      style={{ transform: "rotate(90deg) translate(0px, -246px)" }}
    >
      {collectedProgress}%
    </text>
  </svg>
  )
}

export default ProgressCircle
